import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TechSignInComponent } from "./components/sign-in/tech-sign-in.component";
import { ReactiveFormsModule } from "@angular/forms";
import { TechSigninDialogComponent } from './components/dialog/tech-signin-dialog/tech-signin-dialog.component';
import '@aodocs/authentication';
import { HttpClientModule } from "@angular/common/http";
import { environment } from "../environments/environment";
import { MatDialogModule } from "@angular/material/dialog";
import { ClipboardModule } from "@angular/cdk/clipboard";
import { MatButtonModule } from "@angular/material/button";
import { HeaderComponent } from "./components/header/header.component";
import { MatDividerModule } from "@angular/material/divider";
import { FlexLayoutModule } from "@angular/flex-layout";


@NgModule({
  declarations: [
    AppComponent,
    TechSignInComponent,
    TechSigninDialogComponent,
    HeaderComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatDialogModule,
    BrowserAnimationsModule,
    ClipboardModule,
    MatButtonModule,
    MatDividerModule,
    FlexLayoutModule
  ],
  providers: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]

})
export class AppModule { }
