<section class="generic-dialog">
  <header>
    <h1 mat-dialog-title>{{title}}</h1>
  </header>
  <div mat-dialog-content>
    <p class="{{type}}">{{message}}</p>
    <p>{{description}}</p>
  </div>
  <div fxLayout="row" fxLayoutAlign="end center" mat-dialog-actions>
    <button mat-button [mat-dialog-close]="true" >Close</button>
    <button mat-button  *ngIf="isChoiceBased()" [cdkCopyToClipboard]="message" class="confirm-action-btn">Copy to clipboard</button>
  </div>
</section>
