<section>
  <section class="login-section">
      <form>
        <div class="form-group">
          <input
            [formControl]="domainForm"
            type="text"
            class="form-control domain-input"
            id="domain"
            aria-describedby="domainHelp"
            placeholder="Enter Domain"
            required
          />
        </div>
      </form>
  </section>
  <div class="mat-elevation-z2 sign-in-button aodocs-sign-in-btn">
    <ao-google-login
      [clientId]="clientId"
      [scopes]="scopes"
      (loginSuccess)="googleLoginSuccess($event)"
      (loginError)="loginError()"
      [disabled]="!domainForm.valid"
      display-mode="content"
      authentication-mode="code"
      id="gLogin"
    >
      <div
        class="button-header"
        [class.disabled]="!domainForm.valid"
      >
        <img alt="logo" class="logo-size" src="../assets/google_icon.svg" />
        <span>Sign in with Google</span>
      </div>
    </ao-google-login>
  </div>
</section>
