import {Component} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {environment} from '../../../environments/environment';
import {Scope} from '../../models/scopes';
import {AuthTechService} from '../../services/auth-tech.service';
import {HttpErrorResponse} from '@angular/common/http';
import {DialogService} from '../../services/dialog.service';


@Component({
  selector: 'app-root',
  templateUrl: './tech-sign-in.component.html',
  styleUrls: ['./tech-sign-in.component.scss']
})
export class TechSignInComponent {

  constructor(
    private techAuthService: AuthTechService,
    private dialogService: DialogService,
  ) {
  }

  domainForm = new FormControl(null, [Validators.required]);
  clientId = environment.clientId;
  scopes = [Scope.EMAIL, Scope.PROFILE, Scope.DRIVE, Scope.PUBSUB].join(' ');

  googleLoginSuccess(event: any): void {
    console.log(this.domainForm.value)
    this.techAuthService.signInWithGoogle(event.detail.code, this.domainForm.value as unknown as string)
      .subscribe({
        next: (token) => {
          this.dialogService.plain(token, 'Refresh Token')
        },
        error: async (e: HttpErrorResponse) => {
          this.dialogService.error(e.message, 'Refresh Token')
        },
      })
  }

  loginError(): void {
    this.dialogService.error('Error: An error occurred during authentication', 'Error')
  }

}
