import { Inject, Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { map, Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class AuthTechService {
  constructor(private http: HttpClient) { }

  public signInWithGoogle(authorizationCode: string, domain: string): Observable<string> {
    const params = new HttpParams().set('authorizationCode', authorizationCode).set('domain', domain);

    const options = { params };
    return this.http.post<{ accessToken: string }>("/api/v1/auth/google/techSignIn", {}, options).pipe(map((rep) => rep.accessToken));
  }
}
