import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {DialogType} from "../constants/dialog-types";
import {DialogData} from "../models/dialog/dialog-data.model";
import {TechSigninDialogComponent} from "../components/dialog/tech-signin-dialog/tech-signin-dialog.component";
import {Injectable} from "@angular/core";


@Injectable({
  providedIn: 'root'
})
export class DialogService {

  constructor(private dialog: MatDialog) {
  }

  plain(msg: string, title: string): MatDialogRef<TechSigninDialogComponent, boolean> {
    const dialogData: DialogData = {
      message: msg, title,
      type: DialogType.PLAIN
    };
    return this.open(dialogData);
  }

  error(msg: string, title?: string): MatDialogRef<TechSigninDialogComponent, boolean> {
    const dialogData: DialogData = {
      message: msg,
      title: title || 'Error',
      type: DialogType.ERROR
    };
    return this.open(dialogData);
  }

  open(dialogData: DialogData): MatDialogRef<TechSigninDialogComponent, boolean> {
    return this.dialog.open(TechSigninDialogComponent, {data: dialogData});
  }
}
